/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Box,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";

import { TheNFTAddress } from "../../constants/addresses";
import { getNFTContract } from "../../helpers/contracts";
import { useWeb3Context } from "../../hooks";
import { useEffect, useMemo, useState } from "react";
import {
  NETWORKS,
  DEFAULT_CHAIN_ID,
  USER_SELECTABLE_NETWORKS,
} from "../../constants";

import { Button } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { firstSaleMint } from "../../store/slices/mint-slice";
import { useFormik } from "formik";
import { IReduxState } from "../../store/slices/state.interface";
import { BigNumber } from "ethers";
import Loading from "../../components/Loading";
import { TransactionToastMessage } from "../../components/TransactionToastMessage";
import { toast } from "react-toastify";

const displayToast = async (msg: string, hash: string) => {
  toast(TransactionToastMessage(msg, hash));
};
const scannerURL = NETWORKS[DEFAULT_CHAIN_ID].blockExplorerUrls[0];
type Props = {};

export const FirstSale = () => {
  // 複数のMintを同時に実行できるようになる日
  const serviceStart = 1667638800; // 11/5 18:00~
  const multiMintDate = 1667642400; // 11/5 19:00~
  const now = Math.floor(Date.now() / 1000);

  const {
    connected,
    web3,
    chainID,
    address,
    provider,
    whitelist,
    setWhitelist,
    whitelist2,
    providerChainID,
    setWhitelist2,
  } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);
  const mintLoading = useSelector<IReduxState, boolean>(
    (state) => state.mint.loading
  );
  const mintResult = useSelector<IReduxState, boolean>(
    (state) => state.mint.result
  );

  const dispatch = useDispatch();

  const [merkle, setMerkle] = useState([]);
  const [hexProof, setHexProof] = useState([]);
  const [totalSupply, setTotalSupply] = useState("");
  const [isMintSuccessful, setIsMintSuccessful] = useState<boolean>(); // MINTが成功下かどうか(MINTを実行した時はundefine)
  const isWrongNetwork = useMemo(
    () => isConnected && !USER_SELECTABLE_NETWORKS.includes(providerChainID),
    [isConnected, providerChainID, USER_SELECTABLE_NETWORKS]
  );

  const getMerkleData = (addr: string) => {
    fetch("https://metacamelot.sxg.workers.dev/address/" + addr)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setHexProof(result.hexProof);
          setWhitelist(result.whitelist);
          setWhitelist2(result.whitelist2);
          setMerkle(result);
        },
        (error) => {
          console.log(error);
        }
      )
      .catch((error) => {
        console.error("failed to connect ", error);
      });
  };

  useEffect(() => {
    if (address) {
      console.log("address:", address);
      getMerkleData(address);
    }
  }, [address]);

  const [firstMinted, setFirstMinted] = useState("");
  const [totalMinted, setTotalMinted] = useState("");
  const [totalMintLimit, setTotalMintLimit] = useState("");
  const [firstSaleLimit, setFirstSaleLimit] = useState("");
  const [firstSaleStart, setFirstSaleStart] = useState(false);
  const [firstSaleMintLimit, setFirstSaleMintLimit] = useState("");
  const [firstSaleMintPrice, setFirstSaleMintPrice] = useState("");

  useEffect(() => {
    console.log("mintResult: ", mintResult);
    const con = getNFTContract(provider.getSigner());
    con.totalSupply().then((totalSupply_: BigNumber) => {
      console.log("totalSupply: ", totalSupply_);
      setTotalSupply(totalSupply_.toString());
    });
    if (address) {
      con.firstMinted(address).then((num: number) => {
        setFirstMinted(num.toString());
      });
      con.totalMinted(address).then((num: number) => {
        setTotalMinted(num.toString());
      });
      con.totalMintLimit().then((num: number) => {
        setTotalMintLimit(num.toString());
      });
      con.firstSaleLimit().then((num: number) => {
        setFirstSaleLimit(num.toString());
      });
      con.firstSaleStart().then((num: boolean) => {
        setFirstSaleStart(num);
      });
      con.firstSaleMintLimit().then((num: number) => {
        setFirstSaleMintLimit(num.toString());
      });
      con.firstSaleMintPrice().then((num: BigNumber) => {
        setFirstSaleMintPrice(
          (
            Number.parseInt(
              num.div(BigNumber.from("1000000000000")).toString()
            ) / 1000000
          ).toString()
        );
      });
    }
  }, [mintLoading, isConnected, mintResult]);

  // // 元のfoam処理
  // const formikFirstSaleMint = useFormik({
  //   initialValues: {
  //     mintQuantity: "",
  //   },

  //   onSubmit: (values) => {
  //     console.log("formikFirstSaleMint.onSubmit");
  //     console.log("mintQuantity: ", values.mintQuantity);
  //     const quantity = parseInt(values.mintQuantity);

  //     try {
  //       // console.log("merkle: ", merkle.hexProof[0]);
  //       // const hexProof = merkle.hexProof[0];
  //       console.log("merkle: ", merkle);
  //       dispatch(
  //         //@ts-ignore
  //         firstSaleMint({
  //           account: address,
  //           quantity,

  //           hexProof: hexProof,
  //           // merkle: merkle,
  //           networkID: chainID,
  //           provider,
  //         })
  //       );
  //       setIsMintSuccessful(true);
  //     } catch (e) {
  //       console.log(e);
  //       setIsMintSuccessful(false);
  //     }
  //   },
  // });

  // デザイン適用後のMINT処理
  const [mintNumber, setMintNumber] = useState<number>(1);
  const onSubmit = async () => {
    console.log("formikFirstSaleMint.onSubmit");
    console.log("mintQuantity: ", mintNumber);
    const quantity = mintNumber;
    try {
      // console.log("merkle: ", merkle.hexProof[0]);
      // const hexProof = merkle.hexProof[0];
      console.log("merkle: ", merkle);
      await dispatch(
        //@ts-ignore
        firstSaleMint({
          account: address,
          quantity,

          hexProof: hexProof,
          // merkle: merkle,
          networkID: chainID,
          provider,
        })
      );
      setIsMintSuccessful(true);
    } catch (e: any) {
      console.log(e);
      // toast(e?.reason);
      setIsMintSuccessful(false);
    }
  };

  // useEffect(() => {
  //   // console.log("newSymbol::::::", newSymbol);
  //   // console.log("newAddress::::::", newAddress);
  //   if (newSymbol != "") {
  //     dispatch(clearNewCreatedMint());
  //     navigate("/projects/" + newSymbol);
  //   }
  // }, [newAddress, newSymbol]);
  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  // if (!isConnected) {
  //   return (
  //     <>
  //       <Button onClick={connect} colorScheme="blue">
  //         connect
  //       </Button>
  //     </>
  //   );
  // }
  // if (isConnected && !USER_SELECTABLE_NETWORKS.includes(providerChainID)) {
  //   return (
  //     <>
  //       <Button onClick={checkWrongNetwork} colorScheme="red">
  //         switch network
  //       </Button>
  //     </>
  //   );
  // }

  // connected・・・Walletが接続できているかどうか
  const isOnWhiteList = whitelist || whitelist2; // whiteListかどうか

  const { mintStatusMessage, isValidClassName } = useMemo(() => {
    // MINT未実行、成功、失敗時の制御
    let mintStatusMessage = "";
    let isValidClassName = "";
    // MINT未実行の時は空で返す
    if (typeof isMintSuccessful === "undefined") {
      return {
        mintStatusMessage,
        isValidClassName,
      };
    }

    if (mintLoading) {
      return {
        mintStatusMessage,
        isValidClassName,
      };
    }

    if (isMintSuccessful && mintResult) {
      // MINT成功時
      mintStatusMessage = "MINT COMPLETE！";
      isValidClassName = "main-visual_wl_vailed display-block";
    } else {
      // MINT失敗時
      mintStatusMessage = "MINT FAILED！";
      isValidClassName = "main-visual_wl_invailed display-block";
    }
    return {
      mintStatusMessage,
      isValidClassName,
    };
  }, [isMintSuccessful, mintResult, mintLoading]);

  // スタイリング
  const mintNumButtonStyle = css`
    max-width: 80rem;
    width: 30%;
    border: none;
    background: linear-gradient(
      261deg,
      rgb(23, 152, 194) 0%,
      rgb(34, 208, 199) 100%
    );
    color: #fff;
    padding: 11px;
    font-size: 16rem;
    border-radius: 40rem;
  `;
  const mintNumStyle = css`
    color: #fff;
    font-size: 32rem;
    letter-spacing: 14rem;
    font-family: "Oswald", sans-serif;
    text-align: center;
  `;
  const mintNumWrapper = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  `;

  return (
    <>
      {/* ここからデザインa */}

      <div className="l-data-number">
        <dl>
          <dt>YOUR ENTRIES</dt>
          <dd>
            {firstMinted} / {firstSaleMintLimit}
          </dd>
        </dl>
        <dl>
          <dt>TOTAL ENTRIES</dt>
          <dd>
            {totalSupply} / {firstSaleLimit}
          </dd>
        </dl>
      </div>

      <div className="main-visual_wl_result">
        <span className={isValidClassName}>{mintStatusMessage}</span>
      </div>
      {isMintSuccessful === false && (
        <p className={"wh-read-text_failed display-block"}>
          Something went wrong...
        </p>
      )}

      <p
        className={
          isConnected &&
          isOnWhiteList &&
          (typeof isMintSuccessful === "undefined" || isMintSuccessful)
            ? "wh-read-text_caution display-block"
            : "wh-read-text_caution"
        }
      >
        推奨環境、PCもしくはスマホアプリ「metamask」
        <br />
        ※スマホから上手く出来なかった場合は、PCからmintをしてみてください
        {now < serviceStart && (
          <>
            <br />
            サービス開始は11月5日 18:00～です。
          </>
        )}
        {now < multiMintDate && (
          <>
            <br />
            11月5日 18:00～19:00までは1MINTしかできません。
            <br />
            2MINT以降は11月5日 19:00を過ぎてからお願いします。
          </>
        )}
      </p>
      {!isConnected && (
        <p className="wh-read-text display-block">
          WALLETに接続されていません。
          <br />
          右上の「CONNECT WALLET」ボタンからWALLETに接続してください。
        </p>
      )}
      {isConnected && !isOnWhiteList && (
        <p className="wh-read-text display-block">
          WHITELISTにアドレスがない為
          <br />
          MINTを実行できません。
        </p>
      )}

      {now > multiMintDate ? (
        <>
          <div css={mintNumWrapper}>
            <button
              onClick={() => {
                if (mintNumber > 1) {
                  setMintNumber((prev) => prev - 1);
                }
              }}
              css={mintNumButtonStyle}
            >
              <ArrowLeftIcon />
            </button>
            <div css={mintNumStyle}>{"　　" + mintNumber + "　　"}</div>
            <button
              onClick={() => {
                if (
                  mintNumber <
                  parseInt(firstSaleMintLimit) - parseInt(firstMinted)
                ) {
                  setMintNumber((prev) => prev + 1);
                }
              }}
              css={mintNumButtonStyle}
            >
              <ArrowRightIcon />
            </button>
          </div>
          <div>
            {/* <Slider
          min={1}
          max={parseInt(firstSaleMintLimit) - parseInt(firstMinted)}
          defaultValue={mintNumber}
          onChange={(value) => setMintNumber(value)}
          width="50%"
          height="48rem"
        >
          <SliderTrack bg="red.100" height="48rem">
            <SliderFilledTrack bg="tomato" height="24rem" />
          </SliderTrack>
          <SliderThumb boxSize={6}>
            <Box color="tomato" boxSize={80} />
          </SliderThumb>
        </Slider> */}
          </div>
        </>
      ) : null}

      <div css={mintNumWrapper}>
        <Loading isLoading={mintLoading}>
          <button
            className={
              connected &&
              isOnWhiteList &&
              !isWrongNetwork &&
              !(now < multiMintDate && parseInt(firstMinted) >= 1) &&
              firstSaleStart
                ? "main-visual_wl_btn"
                : "main-visual_wl_btn main-visual_wl_btn_stop"
            }
            disabled={
              !firstSaleStart ||
              !isOnWhiteList ||
              isWrongNetwork ||
              (now < multiMintDate && parseInt(firstMinted) >= 1) ||
              !firstSaleStart
            }
            onClick={onSubmit}
          >
            MINT
          </button>
        </Loading>
      </div>

      {/* デザインここまで */}

      {/* ↓デバック用 */}
      {/* {true ? (
        <p className="wh-read-text">
          確認用表示
          <>isConnected:{isConnected}</>
          <>connected:{connected}</>
          {whitelist ? (
            <>ホワイトリスト: 対象 </>
          ) : (
            <>ホワイトリスト: 対象ではありません</>
          )}
          <br />
          {whitelist2 ? (
            <>スペシャルホワイトリスト: 対象 </>
          ) : (
            <>スペシャルホワイトリスト: 対象ではありません</>
          )}
          <br />
          このアドレスがミントした(owner mint除く）すべてのラウンドでの数：
          {totalMinted}
          <br />
          価格： {firstSaleMintPrice}
          <br />
          これまでのNFT発行数：{totalSupply}
          <br />
          ファーストセールまでの制限(オーナーミント+ファーストセール）：
          {firstSaleLimit}
          <br />
          ユーザあたりのすべてのラウンドでのミント数制限：{totalMintLimit}
          <br />
          このアドレスが ファーストセール でミントした数：{firstMinted}
          <br />
          アドレス当たりのファーストセール制限： {firstSaleMintLimit}
          <br />
          セール状態：{" "}
          {firstSaleStart ? <>セール開催中</> : <>セール未開催/終了/停止中</>}
          <br />
          <form onSubmit={formikFirstSaleMint.handleSubmit}>
            <NumberInput defaultValue={1} min={1}>
              <NumberInputField
                name="mintQuantity"
                onChange={formikFirstSaleMint.handleChange}
                value={formikFirstSaleMint.values.mintQuantity}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Button
              disabled={!firstSaleStart}
              mt={4}
              colorScheme="teal"
              isLoading={mintLoading}
              type="submit"
            >
              First Sale Mint
            </Button>
          </form>
          <br />
          <hr />
          <br />
          <br />
          <hr />
          <br />
          {scannerURL}/address/{TheNFTAddress}
          <hr />
        </p>
      ) : null} */}
    </>
  );
};
export default FirstSale;

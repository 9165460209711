import React, { MouseEventHandler } from "react";

type Props = {
  onClickHandler: () => void;
  text: string;
  style: string;
  isConnected: Boolean;
  isWhiteListMenber: boolean;
};

export const WalletButton: React.FC<Props> = ({
  onClickHandler,
  text,
  style,
  isConnected,
  isWhiteListMenber,
}) => {
  // click時の制御
  const handleClick: any = (event: any) => {
    console.log("clicked");
    if (onClickHandler) {
      event.preventDefault();
      onClickHandler();
    }
  };

  return (
    <>
      <div onClick={handleClick} className="btn-connect-wallet">
        {isConnected && (
          <a
            type="button"
            className={
              isWhiteListMenber ? "btn-connect-owned" : "btn-connect-close"
            }
          >
            {isWhiteListMenber ? "WHITELIST OWNED" : "NO WHITELIST"}
          </a>
        )}

        <a type="button" className={style} style={{ cursor: "pointer" }}>
          <i className="fa-light fa-wallet"></i> {text}
        </a>
      </div>
    </>
  );
};
